import React, { memo } from "react";
import { Collapse, Icon, Typography, Avatar } from "antd";
import { CollapsePanelContent } from "../../../../../Components/UI/CollapsePanelContent/CollapsePanelContent";
import { CERTIFICIAL_CSS } from "../../../../../Utils/Helpers/CERTIFICIAL_CSS";
import { PolicyCollapseBody } from "../../../../../Components/Policy/PolicyCollapseBody/PolicyCollapseBody";
import NumberFormat from "react-number-format";
import { AppliedPolicyTypeOrCslCheckBox } from "../../../../../Components/Policy/AppliedPolicyTypeOrCsl/AppliedPolicyTypeOrCslCheckBox";
import { starGreenIcon } from "../../../../../Assets/assets";
import _ from "lodash";
import {CertRatingCompliance} from '../../../../../Components/UI/CertRating/CertRatingCompliance.js'
import {
  APPLICATION_USER_ROLES,
  CERTIFICIAL,
  CERTIFICIAL_POLICY_TYPES
} from "../../../../../Utils/Constants/Application.constants";
const { Panel } = Collapse;
const { Text } = Typography;
const RequestorRequestPolicyDetailsBody = memo(
  function RequestorRequestPolicyDetailsBody({
    requiredRating,
    policyTypeRequests,
    isRequestedPoliciesAvailable,
    regionCurrency,
    currencySymbol,
    regionId,
    isEmailCoiUpload,
    emailPolicyTypes
  }) {


    return (
      <>
        <Collapse
          bordered={false}
          accordion
          className={"roi-details"}
          defaultActiveKey={[]}
          expandIcon={({ isActive }) => (
            <Icon
              type="down-circle"
              className="color-primary"
              theme="filled"
              style={{ fontSize: "20px" }}
              rotate={isActive ? 180 : 0}
            />
          )}
          destroyInactivePanel={true}
          expandIconPosition={"right"}
        >
          {policyTypeRequests &&
            policyTypeRequests.map(
              (policyType, idx) =>
                (
                  <Panel
                    header={
                      <>
                        <h4 className="margin-0">{policyType.name}</h4>
                        {requiredRating && (
                          <Text
                            type="primary"
                            code
                            className="policyType-rating"
                          >
                            <Avatar
                              src={starGreenIcon}
                              size="small"
                              shape="square"
                              style={{ marginTop: "-5px" }}
                              className="margin-right-sm"
                            />
                            {requiredRating}
                          </Text>
                        )}
                      </>
                    }
                    key={policyType.name + idx}
                    style={CERTIFICIAL_CSS.UI.COLLAPSE}
                  >
                    <CollapsePanelContent>
                      <div key={2}>
                          <AppliedPolicyTypeOrCslCheckBox
                            policy={policyType}
                            readOnlyMode={true}
                            regionId={regionId}
                            isEmailCoiUpload = {isEmailCoiUpload}
                          >
                          </AppliedPolicyTypeOrCslCheckBox> 
                        <PolicyCollapseBody
                          isEmailCoiUpload = {isEmailCoiUpload}
                          emailPolicyTypes = {emailPolicyTypes}
                          isRequestPolicyScreen={true}
                          isPolicyCollapse={true}
                          regionId={regionId}
                          isRestrictedCoverageInput={true}
                          isPolicyEndorsment={true}
                          policyEndorsements={policyType.endorsements}
                          policyEndorsementsOnCheck={() => {}}
                          policyEndorsementsOnEnterData={() => {}}
                          policyEndorsementsReadOnly={true}
                          isPolicyLimitTypeTable={true}
                          ratingCompliance = {isEmailCoiUpload? (<CertRatingCompliance rating={policyType.amBestRating} title={"Requested AMBest Rating"} readonly={true} />):null}
                          isPolicyCoverageTypes={
                            policyType.selectedCoverageTypes &&
                            policyType.selectedCoverageTypes.length
                              ? true
                              : false
                          }
                          isRequiredDocumentTypes={
                            policyType.selectedRequiredDocumentTypes &&
                            policyType.selectedRequiredDocumentTypes.length
                              ? true
                              : false
                          }
                          limitTableTitle={policyType.name}
                          isPropertyPolicy = {policyType.showPropertyLimits !== false}
                          limitTableColumns={[
                            {
                              title: "Limit Type",
                              dataIndex: "name",
                              render: (limitType) => limitType
                            },
                            {
                              title: `Limits`,
                              dataIndex: "limitValue",
                              render: (val) => (
                                <NumberFormat
                                  style={{
                                    textAlign: "right"
                                  }}
                                  allowNegative={false}
                                  value={val}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={currencySymbol ? currencySymbol : "$"}
                                />
                              )
                            }
                          ]}
                          limitTableData={_.map(
                            policyType.limitTypes,
                            (limitItem) => {
                              return {
                                ...limitItem,
                                limitTableLocation: "viewRequest"
                              };
                            }
                          )}
                          isPolicyInsurer={false}
                          isPolicyDocs={false}
                          policyActions={null}
                          selectedCoverageTypes={
                            policyType.selectedCoverageTypes
                          }
                          selectedRequiredDocumentTypes={
                            policyType.selectedRequiredDocumentTypes
                          }
                          boilerMachineryOptionDesc={
                            policyType.boilerMachineryOptionDesc
                          }
                          coInsuranceDesc={policyType.coInsuranceDesc}
                          marginClauseDesc={policyType.marginClauseDesc}
                          otherCtDesc1={policyType.otherCtDesc1}
                          otherCtDesc2={policyType.otherCtDesc2}
                          policyType={policyType}
                        />
                      </div>
                    </CollapsePanelContent>
                  </Panel>
                ) || null
            )}
        </Collapse>
      </>
    );
  }
);

export default RequestorRequestPolicyDetailsBody;
