import {
  Avatar,
  Card,
  Checkbox,
  Divider,
  Empty,
  Input,
  Popover,
  Radio,
  Select,
  Tag
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import {
  coverageAltIcon,
  coverageIcon,
  emptyImage
} from "../../../Assets/assets";
import { CERTIFICIAL_STATUS, CERTIFICIAL, APPLICATION_USER_ROLES, CERTIFICIAL_POLICY_CODE } from "../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import CertInfoTooltip from "../../UI/CertTooltip/CertInfoTooltip";
import { sortAndFilterCoverageType } from "../../../Utils/utility/app.utility";
export const PolicyCoverageTypes = ({
  vehicleCoverage,
  excludeOther: _excludeOther,
  title,
  isDisabledDueToArchive,
  isRequestFromAgent,
  otherValue,
  otherSecondValue,
  onOther2Changes,
  appliesPerOtherValue,
  coverageTypes,
  handleRadioPolicyCoverageForm,
  onPolicyCoverageChange,
  handleOtherCommentChanges,
  readOnlyMode,
  coverageTypeCompliance,
  onOtherChanges,
  policyType,
  policyTypeCode,
  addCoveragesInException,
  loading,
  boilerMachineryOptionDesc,
  coInsuranceDesc,
  marginClauseDesc,
  otherCtDesc1,
  otherCtDesc2,
  isRestrictedCoverageInput,
  allowMargin,
  bordered,
  andOr,
  module,
  source,
  regionId,
  isVehiclePolicyEdit,
  isEmailCoiUpload,
  emailPolicyTypes
}) => {
  const { role } = useSelector((state) => state.app.currentSession);
  const { policyTypes } = useSelector((state) => state.app.applicationReference);

  const [policyDetails, setPolicyDetails] = useState({});
  useEffect(()=>{
    let policyTypesData = policyTypes; 
    const policyDetails = policyTypesData.filter(item=>item.name === 'policyType');
    setPolicyDetails(policyDetails[0])
  },[policyTypes, policyType]);

  const { regionWisePolicyTypeData } = useSelector((state) => state.app);
  let _regionId = regionId+'';
  let regionPolicyTypes = regionWisePolicyTypeData && regionWisePolicyTypeData[[_regionId]] || []
  let userRole = role; 

  if(isEmailCoiUpload !== undefined && isEmailCoiUpload === true) {
    //When user select policy from CoiUploadPage need to pass default role agent
    userRole = APPLICATION_USER_ROLES.AGENT;
    regionPolicyTypes = emailPolicyTypes
  }
  let coverageTypeClone = _.cloneDeep(coverageTypes);
  if(readOnlyMode && regionId && !isVehiclePolicyEdit){
    const policyTypes = _.cloneDeep(regionPolicyTypes);
    let refPolicy = (policyTypes.find(_policy=>policyType == _policy.name));
    if(refPolicy){
      let refPolicyCoverages = refPolicy?.coverageTypes
      if(_excludeOther && userRole == APPLICATION_USER_ROLES.REQUESTER){
        refPolicyCoverages = refPolicyCoverages.filter(
          (item) =>
            !_.includes(
              CERTIFICIAL.COVERAGE_SYMBOL_OTHER_TYPES,
              item.coverageType.toLowerCase()
            )
        )
      }

      if(vehicleCoverage){
        refPolicyCoverages = refPolicyCoverages.filter(c=>c.id !== 4 && c.coverageType !== "other");
      }

      if(role == APPLICATION_USER_ROLES.REQUESTER && source=='supplier'){
        refPolicyCoverages = refPolicyCoverages.filter(c=>!CERTIFICIAL.COVERAGE_SYMBOL_OTHER_TYPES.includes((c.coverageType||'').toLowerCase()));
      }
      let selectedCoverageTypes = refPolicyCoverages?.length ?  _.unionBy(coverageTypes, refPolicyCoverages, 'id')  : null;
      if(refPolicy?.name == 'Property' && selectedCoverageTypes?.length && regionId+'' == "1"){
        let cov = selectedCoverageTypes.find(cov => (cov.subCoverage?.length || cov.coverageType == "Named/Specified Perils"));
        if (cov) {
          cov.subCoverage = (cov.subCoverage || []).filter(_cov => _cov.isApplied)
          cov.showParentCoverage = true
        }
      }
      coverageTypeClone = selectedCoverageTypes;
    }
  }
  const text = (
    <span>
      {/* <Icon type="carry-out" style={{ color: "#3596db" }} /> */}
      <Avatar
        src={coverageAltIcon}
        size="small"
        shape="square"
        className="margin-right-sm"
      />
      Missing Policy Coverage Symbols
    </span>
  );

  const { userData } = useSelector((state) => state.myProfile);


  const generateCoverageTypes = (array) => {
    const arrayList = sortAndFilterCoverageType(array, role, source);
    const mcs90CovEnabled = (arrayList.find(item=>item.coverageType == CERTIFICIAL.MCS90_COVERAGE_TYPE) || {})?.isApplied;

    return (
      <div>
        {(policyType === "Commercial General Liability" &&
          !!!addCoveragesInException && (
            <CGLCoverages
              isDisabledDueToArchive={isDisabledDueToArchive}
              otherValue={otherValue}
              otherSecondValue={otherSecondValue}
              onOther2Changes={onOther2Changes}
              appliesPerOtherValue={appliesPerOtherValue}
              coverageTypes={array}
              onPolicyCoverageChange={onPolicyCoverageChange}
              handleOtherCommentChanges={handleOtherCommentChanges}
              readOnlyMode={readOnlyMode}
              coverageTypeCompliance={coverageTypeCompliance}
              onOtherChanges={onOtherChanges}
              policyType={policyType}
              addCoveragesInException={addCoveragesInException}
              userData={userData}
              otherCtDesc1={otherCtDesc1}
              otherCtDesc2={otherCtDesc2}
              andOr={andOr}
              source={source}
            />
          )) ||
          (policyDetails?.showPropertyLimits !== false && (
            <>
              <PropertyCoverages
                onPolicyCoverageChange={onPolicyCoverageChange}
                readOnlyMode={readOnlyMode}
                addCoveragesInException={addCoveragesInException}
                coverageTypes={array}
                onOtherChanges={onOtherChanges}
                coInsuranceDesc={coInsuranceDesc}
                marginClauseDesc={marginClauseDesc}
              />
              <Divider dashed style={{ margin: "0 0 12px" }} />
            </>
          )) ||
          (arrayList &&
            arrayList.length &&
            policyType !== "Workers Compensation and Employers' Liability" &&
            arrayList.map((coverage, idx) => {
              return (
                <div
                  key={coverage.id}
                  className={
                    coverage.isCoverageHasCompliance
                      ? "coverage-compliance margin-bottom-sm"
                      : coverage.countryId === 2 &&
                        (coverage.id === 5 || coverage.id === 6)
                      ? "grouped margin-bottom-sm"
                      : "margin-bottom-sm"
                  }
                  style={
                    coverage.countryId === 2 &&
                    (coverage.id === 5 || coverage.id === 6)
                      ? { display: "inline-block" }
                      : {}
                  }
                >
                  {module === "exception" ? (
                    <Checkbox
                      disabled={isDisabledDueToArchive || readOnlyMode}
                      //checked={coverage.isApplied}
                      defaultChecked={coverage.isApplied}
                      onChange={
                        (!!onPolicyCoverageChange &&
                          ((e) =>
                            onPolicyCoverageChange({
                              ...coverage,
                              ...{
                                isApplied: e.target.checked
                              }
                            }))) ||
                        (!!addCoveragesInException &&
                          ((e) =>
                            addCoveragesInException({
                              ...coverage,
                              ...{
                                isApplied: e.target.checked
                              }
                            }))) ||
                        (() => {})
                      }
                    > <span style={{ "textTransform": "capitalize" }}>{_.split(coverage.coverageType, "_").join(" ")}</span>
                    </Checkbox>
                  ) : (
                    <Checkbox
                      disabled={isDisabledDueToArchive || readOnlyMode || (mcs90CovEnabled && coverage.coverageType=='other')}
                      checked={coverage.isApplied}
                      //defaultChecked={coverage.isApplied}
                      onChange={
                        (!!onPolicyCoverageChange &&
                          ((e) =>
                            onPolicyCoverageChange({
                              ...coverage,
                              ...{
                                isApplied: e.target.checked
                              }
                            }))) ||
                        (!!addCoveragesInException &&
                          ((e) =>
                            addCoveragesInException({
                              ...coverage,
                              ...{
                                isApplied: e.target.checked
                              }
                            }))) ||
                        (() => {})
                      }
                    > 
                        <span style={{ "textTransform": "capitalize" }}>{_.split(coverage.coverageType, "_").join(" ")}</span>
                    </Checkbox>
                  )}
                  {/* )} */}
                  {coverage.id === 5 &&
                    coverage.countryId === 2 &&
                    arrayList[idx + 1] &&
                    arrayList[idx + 1].id === 6 && (
                      <Text style={{ marginRight: "8px", fontWeight: "bold" }}>
                        OR
                      </Text>
                    )}{" "}
                  {(_.includes(coverage.coverageType, "other") || (_.includes(coverage.coverageType, "different_name_insured") && module !== "exception"))   &&
                    !isRestrictedCoverageInput && (
                      <TextArea
                        defaultValue={otherValue}
                        style={{ margin: "6px auto" }}
                        disabled={
                          isDisabledDueToArchive ||
                          !coverage.isApplied ||
                          readOnlyMode ||
                          mcs90CovEnabled
                        }
                        rows={2}
                        name={"otherDescription"}
                        value={otherValue}
                        onBlur={handleOtherCommentChanges}
                        onChange={onOtherChanges}
                      />
                    )}
                  {coverage &&
                    coverage.dataType &&
                    // coverage.dataType.name === "percent" &&
                    // !isRestrictedCoverageInput &&
                    coverage.coverageType === "co-insurance" && (
                      <NumberFormat
                        style={
                          coverage.isCoverageHasCompliance
                            ? {
                                margin: "6px auto",
                                color: "rgba(243, 11, 23, 0.8)",
                                border: "1px solid #d9d9d9",
                                borderColor: "rgba(243, 11, 23, 0.8)",
                                borderRadius: "4px",
                                padding: "4px 11px",
                                width: "100%"
                              }
                            : {
                                margin: "6px auto",
                                border: "1px solid #d9d9d9",
                                borderRadius: "4px",
                                padding: "4px 11px",
                                width: "100%"
                              }
                        }
                        disabled={
                          isDisabledDueToArchive ||
                          !coverage.isApplied ||
                          readOnlyMode
                        }
                        thousandSeparator={true}
                        suffix={"%"}
                        value={
                          coverage.isApplied
                            ? coverage.isCoverageHasCompliance
                              ? coverage.requestedCoInsuranceDesc
                              : coInsuranceDesc
                              ? coInsuranceDesc
                              : coverage.coInsuranceDesc
                            : null
                        }
                        decimalScale={0}
                        allowEmptyFormatting
                        isAllowed={(values) => {
                          const { value } = values;
                          return value <= 100;
                        }}
                        onValueChange={(values) => {
                          const { value } = values;
                          onOtherChanges &&
                            onOtherChanges({ value, name: "coInsuranceDesc" });
                        }}
                        allowNegative={false}
                        onFocus={(e) => {
                          if (e.target.value === "0%") {
                            e.target.value = "%";
                          }
                        }}
                      />
                    )}
                  {coverage &&
                    coverage.dataType &&
                    // coverage.dataType.name === "percent" &&
                    // !isRestrictedCoverageInput &&
                    coverage.coverageType === "margin_clause" && (
                      <NumberFormat
                        style={
                          coverage.isCoverageHasCompliance
                            ? {
                                margin: "6px auto",
                                color: "rgba(243, 11, 23, 0.8)",
                                border: "1px solid #d9d9d9",
                                borderColor: "rgba(243, 11, 23, 0.8)",
                                borderRadius: "4px",
                                padding: "4px 11px",
                                width: "100%"
                              }
                            : {
                                margin: "6px auto",
                                border: "1px solid #d9d9d9",
                                borderRadius: "4px",
                                padding: "4px 11px",
                                width: "100%"
                              }
                        }
                        disabled={
                          isDisabledDueToArchive ||
                          !coverage.isApplied ||
                          readOnlyMode
                        }
                        thousandSeparator={true}
                        suffix={"%"}
                        value={
                          coverage.isApplied
                            ? coverage.isCoverageHasCompliance
                              ? coverage.requestedMarginClauseDesc
                              : marginClauseDesc
                              ? marginClauseDesc
                              : coverage.marginClauseDesc
                            : null
                        }
                        decimalScale={0}
                        allowEmptyFormatting
                        isAllowed={(values) => {
                          const { value } = values;
                          return value <= 100;
                        }}
                        onValueChange={(values) => {
                          const { value } = values;
                          onOtherChanges &&
                            onOtherChanges({ value, name: "marginClauseDesc" });
                        }}
                        allowNegative={false}
                        onFocus={(e) => {
                          if (e.target.value === "0%") {
                            e.target.value = "%";
                          }
                        }}
                      />
                    )}
                  {coverage &&
                    coverage.dataType &&
                    coverage.dataType.name === "textbox" &&
                    !isRestrictedCoverageInput && (
                      <Input
                        disabled={
                          isDisabledDueToArchive ||
                          !coverage.isApplied ||
                          readOnlyMode
                        }
                        defaultValue={boilerMachineryOptionDesc}
                        style={{ margin: "6px auto" }}
                        name={"boilerMachineryOptionDesc"}
                        value={boilerMachineryOptionDesc}
                        onBlur={handleOtherCommentChanges}
                        onChange={onOtherChanges}
                      />
                    )}
                </div>
              );
            })) ||
          (policyType === "Workers Compensation and Employers' Liability" && (
            <WorkersCoverages
              isDisabledDueToArchive={isDisabledDueToArchive}
              onOtherChanges={onOtherChanges}
              handleRadioPolicyCoverageForm={handleRadioPolicyCoverageForm}
              otherValue={otherValue}
              handleOtherCommentChanges={handleOtherCommentChanges}
              arrayList={arrayList}
              readOnlyMode={readOnlyMode}
              policyType={policyType}
              onPolicyCoverageChange={onPolicyCoverageChange}
              addCoveragesInException={addCoveragesInException}
            />
          )) ||
          null}
      </div>
    );
  };
  return (
    coverageTypeClone?.length || coverageTypeCompliance?.length ? (<Card
      size="small"
      bordered={readOnlyMode || bordered}
      className={[
        "widget-card policy-coverage-types",
        readOnlyMode || bordered || allowMargin
          ? ""
          : "flush-header-padding margin-0"
      ].join(" ")}
      loading={loading}
      title={
        <h4>
          {/* <Icon type="carry-out" style={{ color: "#3596db" }} /> */}
          <Avatar
            src={coverageIcon}
            size="small"
            shape="square"
            className="margin-right-sm"
          />
          {title || "Policy Coverage Symbols"}
          {(isRequestFromAgent &&
            coverageTypeCompliance &&
            coverageTypeCompliance.length && (
              <CertInfoTooltip
                placement="topLeft"
                title={
                  APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO.COVERAGE_TYPES
                }
                className="compliance-issue-info"
              />
            )) ||
            null}
          {(coverageTypeCompliance && coverageTypeCompliance.length && (
            <Popover
              placement="rightTop"
              overlayClassName="compliance-container"
              destroyTooltipOnHide={true}
              title={text}
              content={generateCoverageTypes(coverageTypeCompliance.filter(ele => ele.coverageType !== "Named/Specified Perils"))}
            >
              <Tag color="#f30b17" style={{ float: "right" }}>
                {CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT}
              </Tag>
            </Popover>
          )) ||
            null}
        </h4>
      }
      extra={null}
    >
      {(coverageTypeClone &&
        coverageTypeClone.length &&
        generateCoverageTypes(coverageTypeClone)) || (
        <Empty
          image={emptyImage}
          imageStyle={{
            height: 36
          }}
          description={"No policy coverage available"}
        />
      )}
    </Card>
  ) : null);
};
export const WorkersCoverages = ({
  isDisabledDueToArchive,
  arrayList,
  readOnlyMode,
  handleRadioPolicyCoverageForm,
  otherValue,
  handleOtherCommentChanges,
  onOtherChanges,
  addCoveragesInException
}) => {
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };
  return (
    <>
      <Fragment>
        {!addCoveragesInException ? (
          <Radio.Group
            value={
              arrayList.filter((item) => item.isApplied).length &&
              arrayList.filter((item) => item.isApplied)[0].id
            }
            disabled={isDisabledDueToArchive || readOnlyMode}
            onChange={
              !!handleRadioPolicyCoverageForm
                ? (e) => {
                    // onRadioPolicyCoverageChange(e.target.value);
                    handleRadioPolicyCoverageForm(e.target.value);
                  }
                : () => {}
            }
          >
            {arrayList.map((coverage, index) => {
              return (
                <Radio
                  className={
                    coverage.isCoverageHasCompliance
                      ? "coverage-compliance"
                      : ""
                  }
                  key={index}
                  style={radioStyle}
                  value={coverage.id}
                >
                  {_.startCase(_.split(coverage.coverageType, "_").join(" "))}
                </Radio>
              );
            })}
          </Radio.Group>
        ) : null}
        {addCoveragesInException
          ? arrayList.map((coverage, index) => {
              return (
                <Checkbox
                  disabled={isDisabledDueToArchive || readOnlyMode}
                  defaultChecked={coverage.isApplied}
                  onChange={
                    addCoveragesInException &&
                    ((e) =>
                      addCoveragesInException({
                        ...coverage,
                        ...{
                          isApplied: e.target.checked
                        }
                      }))
                  }
                >
                  <span style={{ "textTransform": "capitalize" }}>{_.split(coverage.coverageType, "_").join(" ")}</span>
                </Checkbox>
              );
            })
          : null}
        {arrayList.map((coverage, index) => {
          return (
            coverage.coverageType === "other" && (
              <TextArea
                key={index}
                defaultValue={otherValue}
                style={{ margin: "6px auto" }}
                disabled={
                  isDisabledDueToArchive || !coverage.isApplied || readOnlyMode
                }
                rows={2}
                name={"otherDescription"}
                value={otherValue}
                onBlur={handleOtherCommentChanges}
                onChange={onOtherChanges}
              />
            )
          );
        })}
      </Fragment>
    </>
  );
};
export const PropertyCoverages = ({
  coverageTypes,

  onPolicyCoverageChange,
  readOnlyMode,

  onOtherChanges,
  coInsuranceDesc,
  marginClauseDesc,
  addCoveragesInException
}) => {
  let coverageWithoutSubCoverage = coverageTypes.filter((i) => !i.hasChild);
  let coverageWithSubCoverage = coverageTypes.filter((i) => i.hasChild);
  coverageWithoutSubCoverage = _.sortBy(
    coverageWithoutSubCoverage,
    ["coverageType"],
    ["asc"]
  );
  coverageWithSubCoverage = _.sortBy(
    coverageWithSubCoverage,
    ["coverageType"],
    ["asc"]
  );
  let res = [...coverageWithoutSubCoverage, ...coverageWithSubCoverage];
  if (
    res &&
    res.length &&
    res.some(
      (i) =>
        i.coverageType === "Named/Specified Perils" &&
        (!i.subCoverage || (i.subCoverage && i.subCoverage.length === 0))
        && !i.showParentCoverage
    )
  ) {
    res = res.filter(
      (r) =>
        r.coverageType !== "Named/Specified Perils" &&
        (!r.subCoverage || (r.subCoverage && r.subCoverage.length === 0))
    );
  }
  return (
    res &&
    res.map((coverage) => {
      const sortedSubcovearge = coverage && coverage.subCoverage && coverage.subCoverage.length > 0 && coverage.subCoverage.sort((a, b) => (a.coverageType > b.coverageType) ? 1 : -1);
      return (
      <div
        key={coverage.id}
        className={
          coverage.isCoverageHasCompliance
            ? "coverage-compliance margin-bottom-sm"
            : coverage.countryId === 2 &&
              (coverage.id === 5 || coverage.id === 6)
            ? "grouped margin-bottom-sm"
            : "margin-bottom-sm"
        }
      >
        <Checkbox
          disabled={readOnlyMode}
          //checked={coverage.isApplied}
          defaultChecked={coverage.isApplied}
          onChange={
            (!!onPolicyCoverageChange &&
              ((e) =>
                onPolicyCoverageChange({
                  ...coverage,
                  ...{
                    isApplied: e.target.checked
                  },
                  subCoverage:
                    (sortedSubcovearge &&
                      sortedSubcovearge.map((item) => ({
                        ...item,
                        isApplied: e.target.checked ? item.isApplied : false
                      }))) ||
                    null
                }))) ||
            (!!addCoveragesInException &&
              ((e) =>
                addCoveragesInException({
                  ...coverage,
                  ...{
                    isApplied: e.target.checked
                  },
                  subCoverage:
                    (sortedSubcovearge &&
                        sortedSubcovearge.map((item) => ({
                        ...item,
                        isApplied: e.target.checked ? item.isApplied : false
                      }))) ||
                    null
                }))) ||
            (() => {})
          }
        >
          <span style={{ "textTransform": "capitalize" }}>{_.split(coverage.coverageType, "_").join(" ")}</span>
        </Checkbox>
          {coverage &&
            coverage.dataType &&
            coverage.coverageType === "co-insurance" && (
              <div style={{ width: "40%" }}>
                <NumberFormat
                  style={
                    coverage.isCoverageHasCompliance
                      ? {
                          margin: "6px auto",
                          color: "rgba(250, 84, 28, 0.8)",
                          border: "1px solid #d9d9d9",
                          borderColor: "rgba(250, 84, 28, 0.8)",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%",
                        }
                      : {
                          margin: "6px auto",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%",
                        }
                  }
                  disabled={!coverage.isApplied || readOnlyMode}
                  thousandSeparator={true}
                  suffix={"%"}
                  value={
                    coInsuranceDesc || coverage.isApplied
                      ? coverage.isCoverageHasCompliance
                        ? coverage.requestedCoInsuranceDesc
                        : coInsuranceDesc
                        ? coInsuranceDesc
                        : coverage.coInsuranceDesc
                      : null
                  }
                  decimalScale={0}
                  allowEmptyFormatting
                  isAllowed={(values) => {
                    const { value } = values;
                    return value <= 100;
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    onOtherChanges &&
                      onOtherChanges({ value, name: "coInsuranceDesc" });
                  }}
                  allowNegative={false}
                  onFocus={(e) => {
                    if (e.target.value === "0%") {
                      e.target.value = "%";
                    }
                  }}
                />
              </div>
            )}
          {coverage &&
            coverage.dataType &&
            coverage.coverageType === "margin_clause" && (
              <div style={{ width: "40%" }}>
                <NumberFormat
                  style={
                    coverage.isCoverageHasCompliance
                      ? {
                          margin: "6px auto",
                          color: "rgba(250, 84, 28, 0.8)",
                          border: "1px solid #d9d9d9",
                          borderColor: "rgba(250, 84, 28, 0.8)",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%",
                        }
                      : {
                          margin: "6px auto",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%",
                        }
                  }
                  disabled={!coverage.isApplied || readOnlyMode}
                  thousandSeparator={true}
                  suffix={"%"}
                  value={
                    marginClauseDesc || coverage.isApplied
                      ? coverage.isCoverageHasCompliance
                        ? coverage.requestedMarginClauseDesc
                        : marginClauseDesc
                        ? marginClauseDesc
                        : coverage.marginClauseDesc
                      : null
                  }
                  decimalScale={0}
                  allowEmptyFormatting
                  isAllowed={(values) => {
                    const { value } = values;
                    return value <= 100;
                  }}
                  onValueChange={(values) => {
                    const { value } = values;
                    onOtherChanges &&
                      onOtherChanges({ value, name: "marginClauseDesc" });
                  }}
                  allowNegative={false}
                  onFocus={(e) => {
                    if (e.target.value === "0%") {
                      e.target.value = "%";
                    }
                  }}
                />
              </div>
            )}
        {(!readOnlyMode &&
          coverage &&
          sortedSubcovearge&&
          sortedSubcovearge.length && (
            <div style={{ marginLeft: "20px" }}>
              <Select
                mode="multiple"
                placeholder="Sub Coverages"
                value={
                  coverage &&
                  sortedSubcovearge &&
                  sortedSubcovearge
                    .filter((item) => item.isApplied)
                    .map((cov) => cov.id)
                }
                maxTagCount={14}
                onChange={(ids) => {
                  const temp = sortedSubcovearge.map((item) => ({
                    ...item,
                    isApplied: ids.includes(item.id)
                  }));
                  if (!!onPolicyCoverageChange) {
                    // const applied = temp.filter((item) => item.isApplied);
                    onPolicyCoverageChange({
                      ...coverage,
                      // isApplied: false,
                      subCoverage: temp
                    });
                  }
                  if (!!addCoveragesInException) {
                    addCoveragesInException({
                      ...coverage,
                      // isApplied: false,
                      subCoverage: temp
                    });
                  }
                }}
                style={{ width: "100%" }}
                className={"margin-bottom-sm"}
                size="small"
                disabled={readOnlyMode || !coverage.isApplied}
              >
                {sortedSubcovearge.map((subCov) => (
                  <Select.Option key={subCov.id} value={subCov.id}>
                    {subCov.coverageType}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )) ||
          null}
        {readOnlyMode &&
          coverage &&
          sortedSubcovearge &&
          sortedSubcovearge.map((subCov, index) => (
            <div
              style={{ marginLeft: "20px" }}
              key={subCov.id}
              className={
                subCov.isCoverageHasCompliance
                  ? "coverage-compliance margin-bottom-sm"
                  : subCov.countryId === 2 &&
                    (subCov.id === 5 || subCov.id === 6)
                  ? "grouped margin-bottom-sm"
                  : "margin-bottom-sm"
              }
            >
              <Checkbox
                disabled={readOnlyMode || !coverage.isApplied}
                checked={subCov.isApplied}
                //defaultChecked={coverage.isApplied}
                onChange={
                  (!!onPolicyCoverageChange &&
                    ((e) => {
                      const temp = [...sortedSubcovearge]; // clone using Object.create incase of a bug
                      temp.splice(index, 1, {
                        ...subCov,
                        isApplied: e.target.checked
                      });
                      // const applied = temp.filter((item) => item.isApplied);
                      onPolicyCoverageChange({
                        ...coverage,
                        // isApplied: false,
                        subCoverage: temp
                      });
                    })) ||
                  (!!addCoveragesInException &&
                    ((e) => {
                      const temp = [...sortedSubcovearge]; // clone using Object.create incase of a bug
                      temp.splice(index, 1, {
                        ...subCov,
                        isApplied: e.target.checked
                      });
                      // const applied = temp.filter((item) => item.isApplied);
                      addCoveragesInException({
                        ...coverage,
                        // isApplied: false,
                        subCoverage: temp
                      });
                      // addCoveragesInException({
                      //   ...subCov,
                      //   ...{
                      //     isApplied: e.target.checked
                      //   }
                      // });
                    })) ||
                  (() => {})
                }
              >
                <span style={{ "textTransform": "capitalize" }}>{_.split(subCov.coverageType, "_").join(" ")}</span>
              </Checkbox>
            </div>
          ))}
      </div>
    )})
  );
};
export const CGLCoverages = ({
  isDisabledDueToArchive,
  otherValue,
  otherSecondValue,
  appliesPerOtherValue,
  coverageTypes,
  onPolicyCoverageChange,
  handleOtherCommentChanges,
  readOnlyMode,
  onOtherChanges,
  onOther2Changes,
  addCoveragesInException,
  otherCtDesc1,
  otherCtDesc2,
  andOr,
  source
}) => {
  const { role } = useSelector((state) => state.app.currentSession);
  const AppliesPerIds = coverageTypes.filter(cov=>CERTIFICIAL.COVERAGE_CATEGORY.GENERAL_AGGREGATE_LIMIT.includes(cov.coverageType)).map(cov=>cov.id);
  const AppliesPerCoverages = _.filter(coverageTypes, function (o) {
    return _.includes(AppliesPerIds, o.id);
  });
  const excludeOther = AppliesPerCoverages.filter(
    (item) => item.coverageType !== "applies_per_other"
  );
  const other = _.find(AppliesPerCoverages, function (item) {
    return item.coverageType === "applies_per_other";
  });
  const AppliesPerCoveragesWithSort = _.sortBy(
    excludeOther,
    (c) => c.coverageType
  );
  if (other) {
    AppliesPerCoveragesWithSort.push(other);
  }

  let otherCovIds = coverageTypes.filter(cov=>CERTIFICIAL.COVERAGE_CATEGORY.COVERAGE_TYPE.includes(cov.coverageType) && cov.coverageType != CERTIFICIAL.STOP_GAP_COVERAGE_TYPE).map(cov=>cov.id)
  if(role !== APPLICATION_USER_ROLES.REQUESTER || source=='supplier'){
    const stopGapId = coverageTypes.find(cov=>cov.coverageType == CERTIFICIAL.STOP_GAP_COVERAGE_TYPE)
    if(stopGapId){
      otherCovIds.push(stopGapId.id);
    }
  }
  const otherIds =
    coverageTypes && coverageTypes[0].countryId === 2
      ? coverageTypes.map((o) => o.id)
      : otherCovIds;
  const OtherCoverages = _.filter(coverageTypes, function (o) {
    return _.includes(otherIds, o.id);
  });
  const excludeOther2 = OtherCoverages.filter(
    (item) => item.coverageType.indexOf("other") === -1
  );
  let other2 = OtherCoverages.filter(
    (item) => item.coverageType.indexOf("other") > -1
  );
  
  other2 = _.sortBy(other2, (c) => c.coverageType);
  
  let OtherCoveragesWithSort =
    coverageTypes && coverageTypes[0].countryId === 2
      ? excludeOther2
      : _.sortBy(excludeOther2, (c) => c.coverageType);

  if (other2) {
    OtherCoveragesWithSort = [...OtherCoveragesWithSort, ...other2];
  }
  return (
    <div>
      {(OtherCoveragesWithSort && OtherCoveragesWithSort.length && (
        <>
          {OtherCoveragesWithSort.map((coverage, idx) => {
            return (
              <div
                key={coverage.id}
                className={
                  coverage.isCoverageHasCompliance
                    ? "coverage-compliance margin-bottom-sm"
                    : coverage.countryId === 2 &&
                      (coverage.id === 5 || coverage.id === 6)
                    ? "grouped margin-bottom-sm"
                    : "margin-bottom-sm"
                }
                style={
                  coverage.countryId === 2 &&
                  (coverage.id === 5 || coverage.id === 6)
                    ? { display: "inline-block" }
                    : {}
                }
              >
                <Checkbox
                  disabled={isDisabledDueToArchive || readOnlyMode}
                  checked={coverage.isApplied}
                  onChange={
                    (!!onPolicyCoverageChange &&
                      ((e) =>
                        onPolicyCoverageChange({
                          ...coverage,
                          ...{
                            isApplied: e.target.checked
                          }
                        }))) ||
                    (!!addCoveragesInException &&
                      ((e) =>
                        addCoveragesInException({
                          ...coverage,
                          ...{
                            isApplied: e.target.checked
                          }
                        }))) ||
                    (() => {})
                  }
                >
                  <span style={{ "textTransform": "capitalize" }}>{_.split(coverage.coverageType, "_").join(" ")}</span>
                </Checkbox>
                {coverage.id === 5 &&
                  coverage.countryId === 2 &&
                  OtherCoveragesWithSort[idx + 1] &&
                  OtherCoveragesWithSort[idx + 1].id === 6 && (
                    <Text style={{ marginRight: "8px", fontWeight: "bold" }}>
                      {andOr ? "AND/OR" : "OR"}
                    </Text>
                  )}{" "}
                {coverage.coverageType === "other" && (
                  <TextArea
                    defaultValue={otherValue}
                    style={{ margin: "6px auto" }}
                    disabled={
                      isDisabledDueToArchive ||
                      !coverage.isApplied ||
                      readOnlyMode
                    }
                    rows={2}
                    name={"otherDescription"}
                    value={otherValue}
                    onBlur={handleOtherCommentChanges}
                    onChange={onOtherChanges}
                  />
                )}
                {coverage.coverageType === "other 2" && (
                  <TextArea
                    defaultValue={otherSecondValue}
                    style={{ margin: "6px auto" }}
                    disabled={
                      isDisabledDueToArchive ||
                      !coverage.isApplied ||
                      readOnlyMode
                    }
                    rows={2}
                    name={"otherTwoDescription"}
                    value={otherSecondValue}
                    // onBlur={handleOtherCommentChanges}
                    onChange={onOther2Changes}
                  />
                )}
                {_.includes(coverage.coverageType, "other_ 1") && (
                  <TextArea
                    defaultValue={otherCtDesc1}
                    style={{ margin: "6px auto" }}
                    disabled={
                      isDisabledDueToArchive ||
                      !coverage.isApplied ||
                      readOnlyMode
                    }
                    rows={2}
                    name={otherValue ? "otherDescription" : "otherCtDesc1"}
                    value={otherCtDesc1}
                    onBlur={handleOtherCommentChanges}
                    onChange={onOtherChanges}
                  />
                )}
                {_.includes(coverage.coverageType, "other_ 2") && (
                  <TextArea
                    defaultValue={otherCtDesc2}
                    style={{ margin: "6px auto" }}
                    disabled={
                      isDisabledDueToArchive ||
                      !coverage.isApplied ||
                      readOnlyMode
                    }
                    rows={2}
                    name={otherValue ? "otherDescription" : "otherCtDesc2"}
                    value={otherCtDesc2}
                    onBlur={handleOtherCommentChanges}
                    onChange={onOtherChanges}
                  />
                )}
              </div>
            );
          })}
        </>
      )) ||
        null}
      {(OtherCoveragesWithSort &&
        OtherCoveragesWithSort.length &&
        AppliesPerCoveragesWithSort &&
        AppliesPerCoveragesWithSort.length && (
          <Divider dashed style={{ margin: "12px 0" }}></Divider>
        )) ||
        null}
      {(AppliesPerCoveragesWithSort &&
        AppliesPerCoveragesWithSort.length > 0 && (
          <>
            <h5>General Aggregate Limit Applies Per</h5>
            {AppliesPerCoveragesWithSort.map((coverage, idx) => {
              return (
                <div
                  key={coverage.id}
                  className={
                    coverage.isCoverageHasCompliance
                      ? "coverage-compliance margin-bottom-sm"
                      : "margin-bottom-sm"
                  }
                >
                  <Checkbox
                    disabled={isDisabledDueToArchive || readOnlyMode}
                    checked={coverage.isApplied}
                    onChange={
                      (!!onPolicyCoverageChange &&
                        ((e) =>
                          onPolicyCoverageChange({
                            ...coverage,
                            ...{
                              isApplied: e.target.checked
                            }
                          }))) ||
                      (!!addCoveragesInException &&
                        ((e) =>
                          addCoveragesInException({
                            ...coverage,
                            ...{
                              isApplied: e.target.checked
                            }
                          }))) ||
                      (() => {})
                    }
                  >
                    <span style={{ "textTransform": "capitalize" }}>{_.last(_.split(coverage.coverageType, "_"))}</span>
                  </Checkbox>{" "}
                  {coverage.coverageType === "applies_per_other" && (
                    <TextArea
                      defaultValue={appliesPerOtherValue}
                      style={{ margin: "6px auto" }}
                      disabled={
                        isDisabledDueToArchive ||
                        !coverage.isApplied ||
                        readOnlyMode
                      }
                      rows={2}
                      name={"otherSecondDescription"}
                      value={appliesPerOtherValue}
                      onBlur={handleOtherCommentChanges}
                      onChange={onOtherChanges}
                    />
                  )}
                </div>
              );
            })}
          </>
        )) ||
        null}
    </div>
  );
};
