import { message } from "antd";
import axios from "axios";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import {
  APPLICATION_USER_ROLES,
  MULTI_AGENTS
} from "../../../Utils/Constants/Application.constants";
import { appHistory } from "../../../Utils/Helpers/History";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { sortAnArrayOfObjByName } from "../../../Utils/utility/app.utility";
import { getUserInformation } from "../MyProfile/MyProfile.asyncRequest";
import * as AppActions from "./Application.actions";
import {
  disable_welcome,
  downloadCOIClearSuccess,
  setOpenRequests,
  setPendingApprovalCount
} from "./Application.actions";
import { ACTION_TYPES } from "./Application.types";
import { difference as _difference } from "lodash";
export const onSuccessLogin = (selectedResponse) => {
  return async(dispatch) => {
    const {
      userFirstLogin,
      primaryContactId,
      id,
      role,
      jwt,
      lastName,
      firstName,
      profileCompleted,
      dummySessionId
    } = selectedResponse;
    if (jwt) {
      _authService.setUserToken(jwt);
      _authService.setUserInfo({
        lastName: lastName,
        firstName: firstName,
        userId: id,
        dummySessionId
      });
      _authService.setUserRole(role);
      // if( selectedResponse.passwordExpiringDays > 0){
        if (role === APPLICATION_USER_ROLES.AGENT) {
          appHistory.push("/app/agent-clients");
        } else {
          if(await doesInsuredHaveOpenRequests(id)){
            appHistory.push("/app/pending-requests");
          }
          else if (!profileCompleted) {
            appHistory.push("/app/myProfile");
          } else {
            dispatch(callGetSupplierCheckAPI(id))
          }
        }
      // }
      dispatch(AppActions.requestedLoginSuccess(selectedResponse));
    }
  };
};


export const doesInsuredHaveOpenRequests = async(userId)=>{
  let flag = false;
  try{
    const response = await callGetOpenRequestCountAPI(userId);
    if(response?.openRequestCount>0) 
    {
      flag = true
    }
  } catch(err){}
  return flag;
}

export const fetchActiveCompany = (email, dummyId, callback) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.AUTHENTICATION.FETCH_ACTIVE_COMPANY, email, dummyId].join(
        "/"
      )
      // options: {
      //   shouldNotShowError: true
      // }
    }).then(
      (response) => {
        callback && callback(response);
      },
      (error) => {
        dispatch(AppActions.requestedLoginError(error));
      }
    );
};

export const login_action = (Credential, callback) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.AUTHENTICATION.LOGIN,
      data: Credential,
      options: {
        shouldNotUserAuthHeaders:true,
        shouldNotShowError: true
      }
    }).then(
      (response) => {
        callback && callback(response, null);
      },
      (error) => {
        if(error?.captcha){
          callback && callback(null, error?.captcha)
        }
        dispatch(AppActions.requestedLoginError(error));
      }
    );
};

export const sso_Authentication_action = (params) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.AUTHENTICATION.SSO_AUTHENTICATION,
    options: {
      params
    }
  });
};
export const disable_welcome_message = () => {
  return (dispatch) => dispatch(disable_welcome());
};

export const getRegionWisePolicyTypeData = (regionIdToExclude)=>{
  return async(dispatch) =>{
    
    let regionArrayToGetPolicyTypes = _difference([1,2],[regionIdToExclude])
    const arrayMethods = regionArrayToGetPolicyTypes.map(id=>{
      return makeRequest({
        type: API_METHODS.GET,
        url:[ENDPOINTS.APPLICATION.REFERENCE_DATA, id].join("/")
      })
    })

   const res = await Promise.all(arrayMethods);
   let payload = {}
    regionArrayToGetPolicyTypes.forEach((regionId, index)=>{
      regionId = regionId+''
      payload[regionId] = res[index]?.policyTypes
    })

    dispatch({type: ACTION_TYPES.APPLICATION.REFERENCE_DATA_POLICY_TYPE,
      payload})
  }

}

export const requestForApplicationReferenceData = (id, callbackFunction) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: id
        ? [ENDPOINTS.APPLICATION.REFERENCE_DATA, id].join("/")
        : ENDPOINTS.APPLICATION.REFERENCE_DATA
    }).then(
      (refData) => {
        const PT = refData.policyTypes.map((item) => {
          const id = item.id;
          let limits = [];
          if (id == 5 || id == 14) {
            limits = item.limitTypes.list;
            limits = sortAnArrayOfObjByName(limits);
          } else {
           if(item.limitTypes !== null && item.limitTypes !== []) {
             if(item.limitTypes.list !== null && item.limitTypes.list.length > 0){
               if(item.limitTypes.list[0].subCategory !== null && item.limitTypes.list[0].subCategory.length > 0){
                limits = item.limitTypes && item.limitTypes.list[0].subCategory
               }
             }
           }
          }
          return {
            ...item,
            limitTypes: limits,
            subLimits: item == 5 ? true : false
          };
        });
        dispatch(
          AppActions.referanceDataSuccess({ ...refData, policyTypes: PT, regionId:id })
        );
        callbackFunction && callbackFunction();
      },
      (error) => {
        dispatch(AppActions.referanceDataError(error));
        callbackFunction && callbackFunction();
      }
    );
};

export const logout_action = (userId, callBackFunction) => {
  const options = {
    params: {
      token: _authService.getUserToken() && _authService.getUserToken()
    }
  };
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: ENDPOINTS.AUTHENTICATION.LOGOUT + "/" + userId,
      options: { ...options }
    }).then(
      (logoutResponse) => {
        window.location.reload();
        message.success(logoutResponse.message);
        _authService.logoutUser();
        _authService.clearUserRole();
        _authService.clearUserInfo();
        _authService.setIsUserTutorialOpen(false);
        dispatch(AppActions.resetStore());
        appHistory.push("/login");
        callBackFunction && callBackFunction();
      },
      (error) => {
        callBackFunction && callBackFunction();
      }
    );
};

export const registration_action = (model) => {
  return makeRequest({
    options: {
      method: API_METHODS.POST,
      headers: { "content-type": "multipart/form-data" },
      data: model,
      url: ENDPOINTS.AUTHENTICATION.REGISTRATION,
      shouldNotUserAuthHeaders: true
    }
  });
};

export const registration_action_post_login = (userId, companyId) => {
  return makeRequest({
    type: API_METHODS.PUT,
    url: ENDPOINTS.MY_TEAM.REGISTRATION_POST_LOGIN,
    options: {
      params: {
        userId,
        companyId
      }
    }
  });
};

export const forgotPassword_action = (data) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.AUTHENTICATION.FORGOT_PASSWORD,
    options: {
      params: data,
      shouldNotUserAuthHeaders: true
    }
  });
};

export const resetPassword_action = (model) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.AUTHENTICATION.RESET_PASSWORD,
    data: model,
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
};

export const updatePassword_action = (model) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.AUTHENTICATION.VERIFY_PASSWORD,
    data: model,
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
};

export const toggleTutorialPopUp = (tutorialChecked) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.AUTHENTICATION.TOGGLE_TUTORIAL_POPUP,
      options: {
        params: tutorialChecked
      }
    }).then(
      (response) => dispatch(handleTutorialPopUp(true)),
      (error) => {}
    );
};
export const handleUserFirstLoginonLogout = (
  { userId, flag, user },
  callBackFunction
) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.AUTHENTICATION.USER_FIRST_LOGIN,
      options: {
        params: {
          userId,
          flag
        }
      }
    }).then(
      (response) => {
        dispatch(handleUserFirstLoginStoreChange(false));
        dispatch(logout_action(userId));
        callBackFunction && callBackFunction();
      },
      (error) => {
        dispatch(handleUserFirstLoginStoreChange(true));
        callBackFunction && callBackFunction();
      }
    );
};
export const handleUserFirstLogin = ({ userId, flag, user }) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.AUTHENTICATION.USER_FIRST_LOGIN,
      options: {
        params: {
          userId,
          flag
        }
      }
    }).then(
      (response) => {
        dispatch(handleUserFirstLoginStoreChange(false));
        message.success(`Welcome, ${user}.`);
        dispatch(disable_welcome_message());
      },
      (error) => {
        dispatch(handleUserFirstLoginStoreChange(true));
      }
    );
};
export const handleTutorialPopUp = (tutorialFlag) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.AUTH.SET_TUTORIAL_POPUP,
      payload: tutorialFlag
    });
  };
};
export const handleUserFirstLoginStoreChange = (flag) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.AUTH.SET_FIRST_LOGIN_FLAG,
      payload: flag
    });
  };
};

export const getState = (countryCode) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.GET_STATE,
    options: {
      params: countryCode,
      shouldNotUserAuthHeaders: true
    }
  });
};

export const getVerificationUserToken = (userToken) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.AUTHENTICATION.VERIFICARTION_EMAIL_TOKEN, userToken].join(
      ""
    ),
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
};

export const getCompanyHistory = (companyName) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: `${ENDPOINTS.APPLICATION.COMPANY_HISTORY}/${companyName}`,
    options: {
      responseType: "blob"
    }
  });
};

export const generateCOIForCompany = (reqObj, companyName) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.POST_GENERATE_COI,
    data: reqObj
  });
};

const useNull = () => {
  return null;
};
export const downloadCOI = (reqObj, companyName) => {
  let reqList = [];

  reqObj &&
    reqObj.map((reqItem, index) => {
      let accForm =
        reqItem.accForm === "Accord24"
          ? "acord24"
          : reqItem.accForm === "Accord25"
          ? "acord25"
          : reqItem.accForm === "Accord28"
          ? "acord28"
          : "";
      delete reqItem.accForm;
      reqList[index] = axios
        .post(
          process.env.REACT_APP_CERT_GEN_URL +
            "policy-" +
            accForm +
            "-get-pdf/download/",
          reqItem,
          {
            responseType: "blob"
          }
        )
        .catch(useNull);
    });
  return axios.all(reqList);
};

export const downloadCOIClear = () => {
  return (dispatch) => {
    dispatch(downloadCOIClearSuccess("null"));
  };
};
export const setProffesionalPlan = (reqObj) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.APPLICATION.UPGRADE_PROFESSIONAL,
      data: reqObj
    }).then(
      (response) => {
        dispatch(getUserInformation(reqObj.userId));
      },
      (error) => {
        // message.error(error);
      }
    );
  };
};
export const getUpgradePlan = (companyId) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.APPLICATION.UPGRADE_PLAN, companyId].join("/")
  });
};

export const getTransactionIdForEnrollment = (payload, callback) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.GET_ENROLLMENT_TRANSACTION_ID,
    data: payload
  }).then(
    (data) => {
      callback && callback(data);
    },
    (error) => {
      callback && callback("");
    }
  );
};

export const sendCertificalContactUsDetails = (model) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.CONTACT_US,
    data: model
  });
};

export const selectedNotification = (data) => {
  return (dispatch) => {
    dispatch(AppActions.selectedNotificationSuccess(data));
  };
};

export const registration_agent_grms = (agentId) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.AUTHENTICATION.REGISTRATION_GRMS_AGENT,
    options: {
      params: agentId,
      shouldNotUserAuthHeaders: true
    }
  });
};
export const downloadUploadedFile = (fileName) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.DOWNLOAD_UPLOADED_FILE,
    options: {
      params: { fileName },
      responseType: "arraybuffer"
    }
  });
};

export const requestForApplicationReferenceCountry = () => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.AUTHENTICATION.REFERENCE_COUNTRY
  });
};

export const requestForApplicationReferenceRegion = () => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.AUTHENTICATION.REFERENCE_REGION
  });
};

export const getMaintainanceStatus = () => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.MAINTAINANCE,
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
};


const callGetOpenRequestCountAPI = (agentId)=>{
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.OPEN_REQUEST_COUNT + "/" + agentId
  })
}

export const callGetSupplierCheckAPI = (agentId)=> {
  return async(dispatch) => {
  const res = await makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.SUPPLIER_DATA_CHECK + "/" + agentId
  })
  let pathToRedirect = res?.shareExist ? "/app/dashboard" : "/app/suppliers"
  appHistory.push(pathToRedirect);
  return;
 }
}

export const getOpenRequestCount = (agentId) => {
  return async(dispatch, getState) => {
    return callGetOpenRequestCountAPI(agentId).then(
      (response) => {
        if (response) {
          const { openRequestCount } = getState().app;
          const openRequests = response.openRequestCount;
          if (openRequestCount !== openRequests) {
            dispatch(setOpenRequests(openRequests));
          }
        }
      },
      (error) => {
        // message.error(error);
      }
    );
  };
};

export const getPendingApproval = (agentId) => {
  return (dispatch, getState) => {
    return makeRequest({
      type: API_METHODS.GET,
      url: ENDPOINTS.APPLICATION.PENDING_APPROVAL_COUNT + "/" + agentId
    }).then(
      (response) => {
        const { pendingApprovalCount } = getState().app;
        const pendingApprovalObj = response;
        if (
          pendingApprovalCount.totalPendingApproval !==
            pendingApprovalObj.totalPendingApproval ||
          pendingApprovalCount.countPolicyVerfication !==
            pendingApprovalObj.countPolicyVerfication ||
          pendingApprovalCount.countPolicyShare !==
            pendingApprovalObj.countPolicyShare ||
          pendingApprovalCount.countPolicyRequest !==
            pendingApprovalObj.countPolicyRequest
        ) {
          dispatch(setPendingApprovalCount(pendingApprovalObj));
        }
      },
      (error) => {}
    );
  };
};

export const getClientFromRequestID = (agentId, value, type) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.APPLICATION.CLIENT_FOR_AGENT_FROM_REQUESTID, agentId].join(
      "/"
    ),
    options: {
      params: { type, value },
      shouldNotShowError: true
    }
  });
};
export const addClientToAgent = (agentId, clientid) => {
  return makeRequest({
    type: API_METHODS.PUT,
    url: [ENDPOINTS.APPLICATION.CLIENT_FOR_AGENT_FROM_REQUESTID, agentId].join(
      "/"
    ),
    options: {
      params: { clientid }
    }
  });
};

export const createNewClientFromAgent = (clientdetails) => {
  return makeRequest({
    options: {
      method: API_METHODS.POST,
      headers: { "content-type": "multipart/form-data" },
      data: clientdetails,
      url: ENDPOINTS.APPLICATION.CREATE_CLIENT_FROM_AGENT,
      shouldNotShowError: true
    }
  });
};

export const getExistingUserInformationViaEmail = (email, role) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.EXISTING_USER_VERFICATION,
    options: {
      shouldNotUserAuthHeaders: true,
      shouldNotShowError: true,
      params: { email, role }
    }
  });
};

export const checkRequestorVerificationPrograme = (params) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.REQUESTOR_VERIFICATION_PROGRAME,
    options: {
      params,
      shouldNotShowError: true
    }
  });
};

export const checkRequestorVerificationCompanies = (payload) => {
  const params = { ...payload, requestorCompanyId: parseInt((payload.requestorCompanyId + '').replace('A', '')) };
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.REQUESTOR_VERIFICATION_COMPANIES,
    options: {
      params,
      shouldNotShowError: true
    }
  });
};
export const createTransactionForPayment = (data) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.CREATE_TRANSACTION_FOR_PAYMENT,
    data,
    options: {
      shouldNotShowError: true
    }
  });
};

export const checkMultiAgentSupport = (incomingRequest, type) => {
  return (dispatch, getState) => {
    const {
      myProfile: {
        userData: { role, id: agentId }
      }
    } = getState();
    if (role && role === APPLICATION_USER_ROLES.AGENT && agentId) {
      switch (type) {
        case MULTI_AGENTS.API_TYPES.REQUEST_DATA:
        case MULTI_AGENTS.API_TYPES.REQUEST_PARAMS:
          return { ...incomingRequest, agentId };
        case MULTI_AGENTS.API_TYPES.REQUEST_QUERY:
          return { ...incomingRequest, agentId };
        case MULTI_AGENTS.API_TYPES.REQUEST_URL:
          return [incomingRequest, agentId].join("/");
        case MULTI_AGENTS.API_TYPES.REQUEST_URL_DELETE_GROUP:
          return incomingRequest;
        default:
          return incomingRequest;
      }
    } else {
      return incomingRequest;
    }
  };
};

export const getComplianceDataFromRequest = (params) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.REQUESTORCOMPLIANCE,
    options: {
      shouldNotUserAuthHeaders: true,
      shouldNotShowError: true,
      params: { ...params }
    }
  });
};

export const agentRemoveClientAction = (params = {}) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.EMAIL_REMOVE_CLENT.REMOVE_CLENT,
    data:  { ...params }
  });
}

export const removeClientAsyncRequest = (params = {}) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.EMAIL_REMOVE_CLENT.REMOVE_CLENT,
    data:  { ...params },
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
}

export const removeClientValidateEmail = (params = {}) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.APPLICATION.EMAIL_REMOVE_CLENT.VALIDATE,
    data:  { ...params },
    options: {
      shouldNotUserAuthHeaders: true
    }
  });
}

export const getAllClientAddressBooks = (insuredCompanyId) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [
      ENDPOINTS.APPLICATION.GET_ALL_ADDRESS_BOOKS_FOR_CLIENT,
      insuredCompanyId
    ].join("/")
  });
};

/**
 * request to get all the client's project templates for an agent
 */
export const getClientProjectTemplatesForAgent = (
  agentId,
  insuredCompanyId,
  regionid,
  role,
  projectId,
  callback
) => {
  const params = { companyid: insuredCompanyId, regionid, role, projectId };
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [
        ENDPOINTS.SHARE_POLICY.GET_CLIENT_PROJECT_TEMPLATES_FOR_AGENT,
        agentId
      ].join("/"),
      options: { params }
    }).then(
      (response) => {
        callback && callback();
        dispatch(AppActions.getClientProjectTemplatesForAgentSuccess(response));
      },
      (error) => {
        callback && callback();
      }
    );
};

/**
 * request to get the client's project template policy details for an agent
 */
export const getClientProjectTemplatesPolicyShareListForAgent = (
  payload,
  callback
) => {
  const messageKey = "applyingTemplate";
  return (dispatch) => {
    message.loading({
      content: "Please wait ",
      key: messageKey,
      duration: 15
    });
    makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.SHARE_POLICY.MY_SHAREABLE_POLICIES,
      data: payload
    }).then(
      (response) => {
        callback && callback(null,response);
        message.success({
          content: "Template applied successfully",
          key: messageKey,
          duration: 2
        });
      },
      (error) => {
        callback && callback(true);
      }
    );
  };
};

/**
 * request to get all the client's address book details for an agent
 */
export const getClientShareAddressBookDetailsForAgent = (
  groupId,
  page,
  isAgencyAddressBook,
  callback
) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [
        ENDPOINTS.ADDRESS_BOOK.GET_ADDRESS_SHARE_DETAILS_FOR_AGENT,
        groupId
      ].join("/"),
      options: {
        params: { pageNumber: page || 1, size: 10, isAgencyAddressBook }
      }
    }).then(
      (response) => {
        callback && callback();
        dispatch(
          AppActions.getClientAddressBookDetailsForShareSuccess({
            ...response,
            groupId
          })
        );
      },
      (error) => {
        callback && callback();
      }
    );
};

export const regenerateCaptchaCode = async (usename = '', refreshCaptcha = false) => {
  return await makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.APPLICATION.REGENERATE_CAPTCHA + "?emailId="+usename+`&refreshCaptcha=${refreshCaptcha}`
  });
}
