import * as _ from "lodash";
import { SHARED_POLICIES_ACTION_TYPES } from "./InsurerSharedPolicies.types";

const initialState = {
  sharedPolicyGraph: {},
  sharedPolicyGraphError: false,
  sharedPolicyGraphLoaded: false,
  selectedCompanyDrawer: {
    value: {},
    isLoaded: false,
    isError: false,
    error: null
  },
  sharedCompanies: [],
  selectedCompanyDrawerPolicy: [],
  deleteSuccessStatus: {
    value: null,
    message: null
  },
  selectedCompany: {},
  myPoliciesToShare: [],
  isError: false,
  projectsForSelectedCompany: [],
  activeProject: null,
  certUploadStatusReRespond: {
    certUpload: null,
    respondToRequest: null,
    enableButton: null
  },
  myDocsPreviousShare: [],
  preSharedSpecialEndorsement: [],
  refreshCompanyId_shareDashboard: null,
  selectedClientTemplateForShare: null,
  isTotalCount: 0,
  loanNumber: "",
  lenderServiceAgent: {},
  selectedAcordForms: [],
  backUpPoliciesByClient: []
};

const baseState = { ...initialState };

const InsurerSharedPoliciesReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES_LIST_VIEW: {
      return { ...state, sharedCompanies: [], selectedCompany: {} };
    }
    case SHARED_POLICIES_ACTION_TYPES.DUMMY_SHARED_COMPANIES: {
      return {
        ...state,
        sharedCompanies: state.sharedCompanies.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_COMPANIES_LIST_VIEW.SUCCESS: {
      return {
        ...state,
        sharedCompanies: payload && payload.payload,
        isDataAvailable: payload.isSearch
          ? true
          : payload && !!payload.payload.length
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.SAVE_TOTAL_PAGINATION: {
      return {
        ...state,
        isTotalCount: payload
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_LIST_VIEW.SUCCESS: {
      if (payload) {
        const selectedCompany = {
          ...payload.payload,
          policies:
            payload &&
            payload.payload &&
            payload.payload.policies &&
            payload.payload.policies.map((p) => ({
              ...p,
              endorsements:
                (p.endorsements &&
                  p.oneTimeEndorsements &&
                  p.oneTimeEndorsements.length &&
                  p.endorsements.length &&
                  _.concat(p.endorsements, p.oneTimeEndorsements)) ||
                (p.endorsements && p.endorsements.length && p.endorsements) ||
                (p.oneTimeEndorsements &&
                  p.oneTimeEndorsements.length &&
                  p.oneTimeEndorsements) ||
                []
            }))
        };

        const newSharedCompanies = _.map(state.sharedCompanies, (obj) => {
          return {
            ...obj,
            ...{ isActive: obj.id === selectedCompany.id }
          };
        });

        return {
          ...state,
          selectedCompany,
          sharedCompanies: newSharedCompanies,
          isDataAvailable: payload.isSearch ? true : !!newSharedCompanies.length
        };
      }
      break;
    }

    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_LIST_VIEW.ERROR: {
      const newSharedCompanies = _.map(state.sharedCompanies, (obj) => {
        return {
          ...obj,
          ...{ isActive: obj.id === payload.selectedSharedCompany.id }
        };
      });

      return {
        ...state,
        selectedCompany: {
          certHolder: {
            ...payload.selectedSharedCompany
          },
          id: payload.selectedSharedCompany && payload.selectedSharedCompany.id,
          name:
            payload.selectedSharedCompany && payload.selectedSharedCompany.name,
          documents: [],
          policies: [],
          specialEndorsements: []
        },
        sharedCompanies: newSharedCompanies,
        isDataAvailable: !!newSharedCompanies.length
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_COMPANIES_LIST_VIEW.ERROR: {
      return state;
    }

    case SHARED_POLICIES_ACTION_TYPES.SET_ACTIVE_SHARED_COMPANIES: {
      const sharedCompanies = _.map(state.sharedCompanies, (obj) => {
        return { ...obj, ...{ isActive: obj.companyId === payload } };
      });
      return {
        ...state,
        sharedCompanies: sharedCompanies,
        selectedCompany: _.find(sharedCompanies, function (o) {
          return o.companyId === payload;
        })
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES: {
      return { ...baseState };
    }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICIES
      .SUCCESS: {
        return {
          ...state,
          selectedCompanyDrawerPolicy: payload,
          deleteSuccessStatus: {
            value: null,
            message: null
          }
        };
      }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICY_DELETE
      .SUCCESS: {
        return {
          ...state,
          deleteSuccessStatus: {
            value: true,
            message: payload
          }
        };
      }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICY_DELETE
      .ERROR: {
        return {
          ...state,
          deleteSuccessStatus: {
            value: false,
            message: payload
          }
        };
      }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER.SUCCESS: {
      return {
        ...state,
        selectedCompanyDrawer: {
          error: null,
          value: payload,
          isError: false,
          isLoaded: true
        },
        deleteSuccessStatus: {
          value: null,
          message: null
        }
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER.ERROR: {
      return {
        ...state,
        selectedCompanyDrawer: {
          error: payload,
          value: null,
          isError: true,
          isLoaded: false
        },
        deleteSuccessStatus: {
          value: null,
          message: null
        }
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_POLICY_GRAPH_VIEW.SUCCESS: {
      return {
        ...baseState,
        sharedPolicyGraph: payload,
        sharedPolicyGraphError: false,
        sharedPolicyGraphLoaded: true
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_POLICY_GRAPH_VIEW.ERROR: {
      return {
        ...baseState,
        sharedPolicyGraphError: true,
        sharedPolicyGraphLoaded: false
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES_GRAPH_VIEW: {
      return { ...baseState };
    }

    case SHARED_POLICIES_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.SUCCESS: {
      return { ...state, myPoliciesToShare: payload}
    }


    case SHARED_POLICIES_ACTION_TYPES.GET_CLIENT_POLICIES_TO_SHARE_FOR_AGENT.SUCCESS: {
      const { policies, selectedTemplateId, isPolicyReShare } = payload;
      const backUpPoliciesByClient = !state.backUpPoliciesByClient.length
        ? _.cloneDeep(state.myPoliciesToShare)
        : _.cloneDeep(state.backUpPoliciesByClient)

      let policiesClone = _.cloneDeep(policies);
      if (selectedTemplateId && policiesClone?.length) {
        const policiesToBeReplaced = _.uniq((policies || []).map(policy => policy?.policyToBeReplaced));
        const policiesToBeReplacedUpdate = [...state.myPoliciesToShare].filter(policy => {
          return policiesToBeReplaced.includes(policy?.policyId)
        });
        policiesClone = [...policiesClone, ...policiesToBeReplacedUpdate];
      }
      
      if (isPolicyReShare && state?.selectedCompany?.coverageTemplateData?.id) {
        if (selectedTemplateId == state.selectedCompany?.coverageTemplateData?.id) {
          const reshareBackupClone = _.cloneDeep(state.reshareByTemplatePolicyBackup)
          const policiesToBeReplacedKeys = _.compact(reshareBackupClone.map(policy=>{
            if(policy.lastShared == true && policy?.policyToBeReplaced)
            {
              return policy.policyToBeReplaced
            }else{
              return null
            }
          }));
         const myPoliciesToShare = reshareBackupClone.filter(p=>p.lastShared == true || policiesToBeReplacedKeys.includes(p.PolicyId))
          return {
            ...state,
            myPoliciesToShare: myPoliciesToShare,
            selectedClientTemplateForShare: selectedTemplateId
          }
        }

        return selectedTemplateId
          ? {
            ...state,
            myPoliciesToShare: policiesClone || [],
            selectedClientTemplateForShare: selectedTemplateId
          }
          : {
            ...state,
            myPoliciesToShare: _.cloneDeep(state.reshareByTemplatePolicyBackup),
            selectedClientTemplateForShare: null,
          };
      }


      return selectedTemplateId
        ? {
          ...state,
          myPoliciesToShare: policiesClone || [],
          selectedClientTemplateForShare: selectedTemplateId,
          backUpPoliciesByClient
        }
        : {
          ...state,
          myPoliciesToShare: backUpPoliciesByClient,
          selectedClientTemplateForShare: null,
          backUpPoliciesByClient: []
        };
    }

    case SHARED_POLICIES_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.ERROR: {
      return { ...state };
    }
    case SHARED_POLICIES_ACTION_TYPES.CLEAR_MY_POLICIES_TO_SHARE: {
      return { ...state, myPoliciesToShare: [] };
    }
    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_PROJECTS_GRAPH_VIEW.SUCCESS: {
      return {
        ...state,
        projectsForSelectedCompany: payload
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.GET_SHARED_PROJECTS_GRAPH_VIEW.ERROR: {
      return {
        ...state
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: payload
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.CLEAR_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: null
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.SAVE_CERT_UPLOAD_STATUS_RE_RESPOND: {
      return {
        ...state,
        certUploadStatusReRespond: payload
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.SUCCESS: {
      return {
        ...state,
        ...(payload?.myPoliciesToShare && { myPoliciesToShare: payload?.myPoliciesToShare }),
        lenderServiceAgent: (payload && payload.lenderServiceAgent) || {},
        loanNumber: (payload && payload.loanNumber) || "",
        myDocsPreviousShare: payload && payload.docsPreviousShare,
        preSharedSpecialEndorsement:
          payload &&
          payload.sharedSpecialEndorsement &&
          payload.sharedSpecialEndorsement.map((item) => {
            return { ...item, value: item.comments };
          }),
        selectedAcordForms: payload?.selectedAcordForms,
        ...(payload?.reshareByTemplatePolicyBackup && { reshareByTemplatePolicyBackup: payload?.reshareByTemplatePolicyBackup }),
        selectedClientTemplateForShare: payload?.selectedClientTemplateForShare
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.ERROR: {
      return {
        ...state
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.RESET: {
      return {
        ...state,
        myDocsPreviousShare: [],
        preSharedSpecialEndorsement: [],
        selectedClientTemplateForShare: null,
        reshareByTemplatePolicyBackup: [],
        selectedAcordForms: [],
        lenderServiceAgent: {},
        backUpPoliciesByClient: []
      };
    }
    case SHARED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.SET: {
      return {
        ...state,
        refreshCompanyId_shareDashboard: payload
      };
    }

    case SHARED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.CLEAR: {
      return {
        ...state,
        refreshCompanyId_shareDashboard: null
      };
    }
    default:
      return state;
  }
};

export default InsurerSharedPoliciesReducer;
