import { PENDING_INSURED_REQUESTS_ACTION_TYPES } from "./pendingInsuredRequests.types";
import _ from "lodash";
const pendingInsuredRequestsInitialState = {
  isRequestedPoliciesAvailable: true,
  requestedPolicies: [],
  selectedRequestedPolicy: {},
  isError: false,
  myPoliciesToShare: [],
  myPoliciesToShareCompliance: [],
  myPoliciesToReRespond: [],
  myDocsToShare: [],
  myDocsPreviousShare: [],
  certUploadStatus: {
    certUpload: null,
    respondToRequest: null
  },
  projectsForSelectedPendingRequest: [],
  activeProject: null
};
const Base_pendingInsuredRequestsInitialState = {
  ...pendingInsuredRequestsInitialState
};
const PendingInsuredRequestsReducer = (
  state = pendingInsuredRequestsInitialState,
  { type, payload }
) => {
  switch (type) {
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.DUMMY_PENDING_INSURED_REQUESTS: {
      return {
        ...state,
        requestedPolicies: state.requestedPolicies.concat(
          payload.map((load) => ({
            ...load,
            loading: false,
            name: {}
          }))
        )

        // selectedRequestedPolicy: _.head(payload)
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.SAVE_CERT_UPLOAD_STATUS: {
      return {
        ...state,
        certUploadStatus: payload
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.RESET_DUMMY_PENDING_INSURED_REQUESTS: {
      return {
        ...state,
        isRequestedPoliciesAvailable: false,
        requestedPolicies: []
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PENDING_INSURED_REQUESTS
      .SUCCESS: {
      return {
        ...state,
        isRequestedPoliciesAvailable: !!payload.length,
        requestedPolicies: payload
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_ACTIVE_PENDING_INSURED_REQUESTS: {
      return {
        ...state,
        requestedPolicies: payload
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_DETAILED_INSURED_REQUEST.SUCCESS: {
      const requestedPolicies = _.map(state.requestedPolicies, (obj) => {
        return {
          ...obj,
          ...{
            isActive: (obj?.requester_company_id === payload?.requester_company_id) && (obj?.certHolderId === payload?.certHolderId)
          }
        };
      });
      return {
        ...state,
        requestedPolicies: requestedPolicies,
        selectedRequestedPolicy: payload
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_DETAILED_INSURED_REQUEST
      .RESET: {
      return {
        ...state,
        requestedPolicies: [],
        selectedRequestedPolicy: []
      };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.CLEAR_PENDING_INSURED_REQUESTS: {
      return { ...Base_pendingInsuredRequestsInitialState };
    }

    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE
      .SUCCESS: {
      let checkedPolicies = payload ? payload.policies : [];
      if (checkedPolicies?.length) {
          const policyListModified = checkedPolicies.map(v => ({ ...v, isNotManagedByMe: v?.unavailableCoverage || false }));
          checkedPolicies = policyListModified;
      }      
      return {
        ...state,
        extractedDooRemarks: payload.extractedDooRemarks ? payload.extractedDooRemarks : "",
        myPoliciesToShare:
          payload && payload.sharedPolicy && payload.sharedPolicy.length
            ? 
            _.orderBy(
              [
                ..._.uniqBy(payload.sharedPolicy, "policyId"),
                ...checkedPolicies
              ],
              [(policy) => policy.policyType.name.toLowerCase()],
              ["asc"]
            )
            : checkedPolicies,
        myPoliciesToShareCompliance: payload && payload.complaince,
        myPoliciesToReRespond: payload && payload.sharedPolicy,
        myDocsPreviousShare: payload && payload.docsPreviousShare
          ? payload.docsPreviousShare
          : [],
        accordForm: {
          requested:payload.requestedAcordForms,
          selected:payload.selectedAcordForms
        }
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.ERROR: {
      return { ...state };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.RESET: {
      return {
        ...state,
        myPoliciesToShare: [],
        myPoliciesToShareCompliance: [],
        myPoliciesToReRespond: [],
        myDocsPreviousShare: []
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.SUCCESS: {
      return { ...state, myDocsToShare: payload };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.ERROR: {
      return { ...state };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.RESET: {
      return { ...state, myDocsToShare: [] };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PROJECTS_LIST.SUCCESS: {
      return {
        ...state,
        projectsForSelectedPendingRequest: payload
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PROJECTS_LIST.ERROR: {
      return {
        ...state
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: payload
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.CLEAR_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: null
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: payload
      };
    }
    case PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_SELECTED_COMPANY: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};

export default PendingInsuredRequestsReducer;
